import React, { useState } from 'react';
import axios from 'axios';
import '../views/waitlist.css'
import check from '../assets/waitlist-assets/check.png'
import Navbar from '../../components/controllers/Navbar';

function Popup() {
  return (
    <div className='popup-container'>
      <img src = {check} className='popup-check' alt = '' />
      <p className='popup-message'>Thank you for joining the waitlist. We will reach out to you soon!</p>
    </div>
  );
}

function WaitlistForm() {
  const [isPopupVisible, setPopupVisible] = useState(false);
    const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      email: '',
      athleteType: ''
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const response = await axios.post('https://script.google.com/macros/s/AKfycbyIAH4_56FIX25lw5QAztLPD4KSrOH-7pcGVHgQuxKIQAIRiZxFjM2wuMxZ8cuoX1cY/exec', null, {
        params: formData
      });
      console.log(response.data);
      setPopupVisible(true);
    };
  
    return (
      <div>
        {!isPopupVisible && <form onSubmit={handleSubmit}>
          <div className='form-container'>
              <div className='name-container'>
                  <input type="text" name="firstName" className='name-field' placeholder = "First Name" value={formData.firstName} onChange={handleChange} required />
                  <input type="text" name="lastName" className='name-field' placeholder = "Last Name" value={formData.lastName} onChange={handleChange} required />
              </div>
              <div>
                  <input type="email" name="email" className='email-field' placeholder = "Email Address" value={formData.email} onChange={handleChange} required />
              </div>
              <div className = "athlete-container">
                  <label className = "athlete-text">What type of Athlete are You?</label>
              </div>
              <div>
                  <select name="athleteType" className='athlete-select' value={formData.athleteType} onChange={handleChange} required >
                      <option value= "" disabled hidden></option>
                      <option value = "Endurance" className = 'athlete-option'> Endurance</option>
                      <option value = "Strength" className = 'athlete-option'> Strength</option>
                      <option value = "Speed" className = 'athlete-option'> Speed</option>
                      <option value = "Reactivity" className = 'athlete-option'> Reactivity</option>
                  </select>
              </div>
              <div className='data-container'>
                  <img src = {check} className='check' alt = ''></img>
                  <p className='data-text'> Your personal data is stored safe and secure with us</p>
              </div>
              <button type="submit" className='submit-button'>Submit</button>
          </div>
        </form>}
        {isPopupVisible && <Popup />}
      </div>
    );
  };
  
  export default function Waitlist() {
    return (
        <div className='waitlist-page'>
          <Navbar className='navbar'/>
            <div className='center-text'>
                <h1 className = "waitlist-heading"> Launching Soon</h1>
                <p className='waitlist-subheading'> Until then... <br/> Join our newsletter and be the first to get updates!</p>
                <WaitlistForm />
            </div>
        </div>
    )
  }